import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"variable\":\"--sub-font\",\"display\":\"swap\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/GoogleAdsense.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GoogleAnalytics"] */ "/vercel/path0/src/components/GoogleAnalytics/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MicrosoftClarity"] */ "/vercel/path0/src/components/MicrosoftClarity/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/global.scss");
